/***
 *** custom scrollbar
 ***/

.iot-statusSet {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.iot-statusSet > * {
  display: inline;
}




/* .iot-size-smaller, */
.iot-size-smaller input,
.iot-size-smaller button,
.iot-size-smaller fieldset {
  padding: 3px 5px !important;
  font-size: 12px !important;
}

/*
 * IOTBox
 */

.iot-box {
  margin: 20px; /* keep a margin around the box for style effects that mean to bleed out */
  position: relative;
}

.iot-box-title {
  position: relative;
  text-align: left;
  padding: 10px 0;
}

.iot-box-border-flourish {
  position: absolute;
  height: 90px;
  width: 90px;
  border-radius: 10px;
}








.iot-leaflet-icon {
  /* background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px; */
}


.leaflet-div-icon {
  border: 0 !important;
  background-color: transparent !important;
}

.iot-map-marker-tooltip {
  background-color: white;
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  font-size: 20px;
  z-index: 1000;
}


.leaflet-tooltip.custom-tooltip, .iot-leaflet-tooltip {
  background: transparent;
  border: none;
  box-shadow: none;
  /* You may need additional styles to achieve the exact appearance you want */
}

.leaflet-popup-tip {
  display: none;
  width: 0;
  height: 0;
  border: 0;
}

.leaflet-popup-tip-container {
  display: none;
}
/*  
 *  account page stylings
 */

.iot-view-account {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.iot-account-card {
  border: 1px solid #999;
}
.iot-locations {}

.iot-location-form-table {
  width: 100%;
  text-align: center;
}

.iot-location-form-table-header {
  text-align: center !important;
}

.iot-location-form-table,
.iot-location-form-table td,
.iot-location-form-table th,
.iot-location-form-table input {
  padding: 0 6px;
  margin: auto;
  font-size: 14px;
}

.iot-location-form-table,
.iot-location-form-table td,
.iot-location-form-table th {
  border: 0
}

.iot-location-form-table-timepicker:first-child,
.iot-location-form-table-sideheader:first-child {
  padding: 5px 0;
}

.iot-location-form-table-timepicker {
  text-align: center;
  width: "100%";
}

.iot-location-form-table-sideheader {
  text-align: right !important;
}

/* .iot-time-picker,
.iot-date-picker > div,
.iot-date-picker > div > div.react-datepicker__input-container,
.iot-date-picker > div > div.react-datepicker__input-container > input {
  width: 100%;
}
 */

 
.iot-time-picker > * {
  width: 100%;
}


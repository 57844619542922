/*  
 *  High level stylings for app layout (header, footer, nav, etc)
 */

.iot-top-layout {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #000133;
}

/*  
 *  body / content
 */

.iot-body {
  display: flex;
  flex: 1;
}

.iot-mainContainer {
  height: calc(100vh - 100px);
  overflow: auto;
  position: relative;
}

.iot-background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200px 100px;
  /* background-size: contain; */

  /* width: 150px; */
  /* -webkit-filter: drop-shadow(8px 8px 8px #fff); */
  /* filter: drop-shadow(0px 0px 5px #ffffff); */
}

.iot-body-nav-container {
  width: 220px;
}

/*  
 *  header
 */

.iot-header {
  height: 50px;
}


.iot-header--burger {
  color: black;
  padding: 10px;
  width: 35px;
  text-align: center;
}

.iot-header--content--item,
.iot-footer--content--item {
  flex-grow: 1;
  max-width: calc(100% / 3)
}

.iot-header--content--placement {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
}

.iot-header--content--container {
  height: 100%;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.iot-header--burger:hover {
  cursor: pointer;
}

.iot-header--background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
}

.iot-header--background--placement {

}

.iot-header-left-logo {
  /* margin: auto;
  width: 100%;
  position: relative; */
}

/*  
 *  leftnav
 */

.iot-leftnav {
  overflow: hidden;
  width: 60px;
  text-align: left;
  transition: width 0.3s;
  position: relative;
}

.iot-leftnav--opened {
  width: 230px;
}

/*  
 *  footer
 */

.iot-footer {
  position: relative;
  width: 100%;
  height: 50px;
}

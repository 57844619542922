.iot-organization-users td, .iot-organization-projects td,
.iot-device-status * {
  font-size: 15px !important;
}


.iot-organization-users-table-cell.iot-pending {
  background-color: lightyellow;
  font-style: italic;
}

.iot-organization-users-table-cell.iot-table-header,
.iot-organization-projects-table-cell.iot-table-header,
.iot-table-header {
  font-weight: bold;
}


/*
 *  Pending invite rounded box
 */
 .iot-organization-users-table .iot-organization-users-table-cell:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.iot-organization-users-table .iot-organization-users-table-cell:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}


/*  
 *  "micro" styles
 */

.iot-device-status {
  /* font-size: 18px; */

}

.iot-device-status-value {
  font-weight: bold;
}

.iot-device-status-value .iot-device-bad {
  font-weight: bold;
  color: red;
}

.iot-device-status-value .iot-device-good {
  font-weight: bold;
  color: green;
}

.iot-device-status-slash {
  color: black;
}

.iot-device-status-total {
  color: black;
}


/*
 *  org modal
 */

 /* <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}> */

.iot-organization-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  width: 33%;
  text-align: center;
}

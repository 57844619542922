@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/***
 *** custom scrollbar
 ***/

/* width */
.iot-detail-cell-state::-webkit-scrollbar {
  height: 2px;
  /* width: 1px; */
}

/* Track */
.iot-detail-cell-state::-webkit-scrollbar-track {
  background: #dedede;
}

/* Handle */
.iot-detail-cell-state::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.iot-detail-cell-state::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/***
 *** tab icons inline
 ***/

.iot-inline-tab-icons {
  flex-direction: row !important;
}

.iot-inline-tab-icons svg {
  margin: 0 5px 0 0 !important;
}


